import { useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import { toast } from "react-toastify";
import { Timeout } from "react-number-format/types/types";
import { useAppDispatch } from "../../hooks/storeHooks";
import Button, { ButtonThemes } from "../../components/button/button";
import { ReactComponent as MoreIcon } from "../../images/more.svg";
import SlideInModal from "../../components/slide-in-modal/slideInModal";
import XGSIcon from "../../components/icon/xgsIcon";
import XGSIcons from "../../components/icon/xgsIcons";
import LabeledSelectInput from "../../components/molecules/labeled-inputs/labeled-select-input/labeledSelectInput";
import WebClockState from "../../slices/web-clock/webClockState";
import { addClockRecords, currentLocationSelector, setLocationId, webClockSelector } from "../../slices/web-clock/webClockSlice";
import { PaylocityRecordTypes, PunchTypes, TerminalLocationModel } from "../../app/data/web-clock/models";
import { getTz } from "../../app/data/common/getTz";
import { getRecordLabel } from "../../app/data/web-clock/helpers";

import "./webClock.scss";
import "../../styles/modal.scss";

interface WebClockProps {
  onMenuClick: () => void;
}

export const WebClock: React.FC<WebClockProps> = (props) => {  
  const webClockState: WebClockState = useSelector(webClockSelector);
  const { punchDetails, request } = webClockState;
  const location = useSelector(currentLocationSelector);
  const [timer, setTimer] = useState(0);
  const [activeRecordType, setActiveRecordType] = useState<PaylocityRecordTypes | null>(null);
  const [selectedLocation, setSelectedLocation] = useState(location);
  const dispatch = useAppDispatch();
  const timerInterval = useRef<Timeout>();

  const locationsOptions = webClockState.locations.map(location => ({ label: location.terminalName, value: location.terminalCode }));

  const lastPunch = punchDetails?.[punchDetails.length - 1];  

  const isNotActive = !lastPunch || lastPunch.relativeEnd;

  const isLunch = lastPunch?.punchType === PunchTypes.LUNCH;

  const hadLunch = punchDetails?.find((punch) => punch.punchType === PunchTypes.LUNCH && punch.relativeEnd);

  const getNow = useCallback(() => dayjs().tz(getTz(location?.terminalZone)), [location?.terminalZone]);

  const getDateTimeFromString = useCallback((dateTime: string) => {    
    return dayjs.tz(dateTime, getTz(location?.terminalZone));
  }, [location?.terminalZone]);

  const formatInterval = (minutes: number) => {
    const interval = [
      Math.floor(minutes / 60).toString(),
      (minutes % 60).toString()
    ];
    return interval[0].padStart(2, '0') + ':' + interval[1].padStart(2, '0');
  }

  useEffect(() => {
    clearInterval(timerInterval.current);
    timerInterval.current = undefined;     

    if (!timerInterval.current && punchDetails?.length) {
      const intervalId = setInterval(() => {        
        const workInterval = punchDetails
        .filter(punch => punch.punchType === PunchTypes.WORK)
        .reduce((result, punch) => {
          const start = getDateTimeFromString(punch.relativeStart);
          const end = punch.relativeEnd ? getDateTimeFromString(punch.relativeEnd) : getNow();
          const interval = end.diff(start, "minute");          
          return result + interval;
        }, 0)

        setTimer(workInterval);
      }, 1000);

      timerInterval.current = intervalId;
    }
  }, [punchDetails, location?.terminalZone, getDateTimeFromString, getNow]);

  useEffect(() => { 
    setTimer(0);
   }, [location?.terminalZone]);   
  
  const addRecord = (recordType: PaylocityRecordTypes, onSuccess: () => void) => {
    if (recordType === PaylocityRecordTypes.IN) {

      let diff = 4;
      const lastRecordTime = punchDetails && punchDetails.length && punchDetails[punchDetails?.length - 1].relativeEnd;

      if (lastRecordTime && selectedLocation?.terminalZone !== location?.terminalZone ) {
        const now = getNow();
  
        diff = now.diff(getDateTimeFromString(lastRecordTime), "hour");
      }
      
      if (diff >= 4) {
        dispatch(setLocationId(selectedLocation?.terminalCode));
      } else {
        toast.error("Clock In in another terminal is unavailable for 4 hours from the last entry");
        return;
      }
    }

    const records = recordType === PaylocityRecordTypes.OUT && isLunch ? [{ recordType: PaylocityRecordTypes.END_LUNCH }, { recordType }] : [{ recordType }]
    dispatch(addClockRecords(
      records,
      onSuccess,
      (error: string) => {
        toast.error(error);        
      },
      () => {
        onSuccess();
        toast.info("The record has been queued to be submitted when the Driver App is online!");        
      }
    ));
  };  

  const getConfirmationText = () => {
    switch (activeRecordType) {
      case PaylocityRecordTypes.IN: return "Do you want to start your workday with ‘Clock In’ button? You may also change your work location before Clock In.";
      case PaylocityRecordTypes.OUT: return "Do you want to end your workday with ‘Clock Out’ button?";
      case PaylocityRecordTypes.START_LUNCH: return "Do you want to start lunch time with ‘Start Lunch’ button?";
      case PaylocityRecordTypes.END_LUNCH: return "Do you want to end lunch time with ‘End Lunch’ button?";
      default: return "";
    }
  }
  
  const onCloseSidebar = () => {
    setActiveRecordType(null);
    setSelectedLocation(location);
  }
  
  const isWebClockActive = !webClockState.request["GET_CLOCK_RECORDS"]?.requestFailed;

  return (
    <div className="xgs-web-clock xgs-card__block xgs-card__block--no-interaction">
      <div className="xgs-web-clock__items">
        <div className={`xgs-web-clock__item ${isWebClockActive ? "" : "xgs-web-clock__item--inactive"}`}>
          <div className="xgs-web-clock__item-label xgs-web-clock__item-label--lg">
            Total time:
          </div>
          <div className="xgs-web-clock__item-value xgs-web-clock__item-value--lg">
            {isWebClockActive ? formatInterval(timer) : "00:00"}
          </div>
        </div>

        {isWebClockActive ? (
          <div className="xgs-web-clock__item">
            <span className="xgs-web-clock__item-label">Location:</span>
            <span className="xgs-web-clock__item-value xgs-web-clock__item-value--inline">{location?.terminalName}</span>
          </div>
        ) : (
          <div className="xgs-web-clock__item">
            <span className="xgs-web-clock__item-value">
              <XGSIcon icon={XGSIcons.faExclamationCircle}/>
              &nbsp;
              WebClock is temporary unavailable. Please, refresh later.</span>
          </div>
        )}
      </div>

      {isWebClockActive && (<div className="xgs-web-clock__buttons">
        {isNotActive ? (
          <Button
            theme={ButtonThemes.blue}
            className="xgs-web-clock__action-button"
            onClick={() => {setActiveRecordType(PaylocityRecordTypes.IN)}}            
          >
            {getRecordLabel(PaylocityRecordTypes.IN)}
          </Button>
        ) : (
          <>
            {!hadLunch && !isLunch && (
              <Button
                theme={ButtonThemes.blue}
                className="xgs-web-clock__action-button"
                onClick={() => {setActiveRecordType(PaylocityRecordTypes.START_LUNCH);}}                
              >
                {getRecordLabel(PaylocityRecordTypes.START_LUNCH)}
              </Button>
            )}

            {isLunch && (
              <Button
                theme={ButtonThemes.red}
                className="xgs-web-clock__action-button"
                onClick={() => {setActiveRecordType(PaylocityRecordTypes.END_LUNCH);}}
              >
                {getRecordLabel(PaylocityRecordTypes.END_LUNCH)}
              </Button>
            )}

            <Button
              theme={ButtonThemes.blueInverted}
              className="xgs-web-clock__action-button"
              onClick={() => {setActiveRecordType(PaylocityRecordTypes.OUT);}}
            >
              {getRecordLabel(PaylocityRecordTypes.OUT)}
            </Button>
          </>
        )}

        <Button
          theme={ButtonThemes.blueInverted}
          className="xgs-web-clock__menu-button"
          onClick={props.onMenuClick}
        >
          <MoreIcon />
        </Button>
      </div>
      )}

      <SlideInModal
        title={getRecordLabel(activeRecordType)}
        show={!!activeRecordType}
        onClose={onCloseSidebar}
      >
        <div className="xgs-web-clock__confirmation-text">{getConfirmationText()}</div>

        {activeRecordType === PaylocityRecordTypes.IN && (
          <LabeledSelectInput
            className="xgs-web-clock__terminal-selector"
            placeholder="Select..."
            value={selectedLocation ? { label: selectedLocation.terminalName, value: selectedLocation.terminalCode } : null}
            onValueChange={(option) => {
              const selectedOption = webClockState.locations.find(location => location.terminalCode === option?.value) as TerminalLocationModel;
              setSelectedLocation(selectedOption);
            }}
            options={locationsOptions}
            menuPlacement="top"
          />
        )}

        <div className="xgs-modal__buttons">
          <Button
            theme={ButtonThemes.blue}
            className="xgs-web-clock__action-button"
            onClick={() => {
              if (!activeRecordType) return;
              addRecord(activeRecordType, () => {
                setActiveRecordType(null);
              });
            }}
            disabled={ request["ADD_CLOCK_RECORD"]?.requestStarted }
            spinner={ request["ADD_CLOCK_RECORD"]?.requestStarted }
          >
            {getRecordLabel(activeRecordType)}
          </Button>

          <Button
            theme={ButtonThemes.gray}
            className="xgs-web-clock__action-button"
            onClick={onCloseSidebar}
          >
            Cancel
          </Button>
        </div>
      </SlideInModal>
    </div>
  )
}