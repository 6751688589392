import { object, string } from "yup";
import { validationMessages } from "../common/validationMessages";
import { ExceptionModel } from "../exceptions/models";
import { StopStatus } from "../common/constants";

export interface ProbillItem {
  id: string;
  identifier: string;
  probill: number;
  description: string;
  weight?: string;
  exception: ExceptionModel | null;
}

export interface ProbillDetails {
  probill: number;
  weight: number;
  itemDetails: ProbillItem[];
  items: number;
  exception: ExceptionModel | null;
}

export interface ConsigneeDetails {
  name: string;
  address: {
    address1: string;
    city: string;
    state: string;
    postalCode: string;
    additionalAddressLine?: string;
  };
  probills: ProbillDetails[];
  delivered: boolean;
  exception: ExceptionModel | null;
}

export interface StopDetails {
  status: StopStatus;
  probillsCount: number;
  itemsCount: number;
  consignees: ConsigneeDetails[];
  type: "PICKUP" | "DELIVERY"
  order: number;
  specialInstructions: string;
  address: {
    address1: string;
    city: string;
    state: string;
    postalCode: string;
    additionalAddressLine?: string;
  };
}

export interface SubmitPodRequest {
  signature: string;
  signer: string;
  notes: string;
  probills?: number[];
  stop?: number;
  firstEmailRecipient?: string;
  secondEmailRecipient?: string;
  thirdEmailRecipient?: string;
};

export const SubmitPodModelRequestSchema = object({
  signature: string(),
  signer: string().min(3, "Minimum 3 characters").required(validationMessages.required),
  // "email()" in this Yup's version allows emails like "name@domain" so the custom handler has been added
  firstEmailRecipient: string()
    .matches(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, validationMessages.notValid)
    .max(60, "The value must be no more than 60 characters"),
  secondEmailRecipient: string()
    .matches(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, validationMessages.notValid)
    .max(60, "The value must be no more than 60 characters"),
  thirdEmailRecipient: string()
    .matches(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, validationMessages.notValid)
    .max(60, "The value must be no more than 60 characters")
});

export interface SubmitPodResponse {
  status: StopStatus;
  stop: number;
}

export const SubmitPickupRequestSchema = object({
  probillNumber: string().matches(/\S+/, validationMessages.notValid)
})